// Production
const baseRestApiUrl = 'https://api.itsovertime.com';
const basePlatformsApiUrl = 'https://api.itsovertime.com';

// Local development
// const basePlatformsApiUrl = 'http://overtime.home:8082';
// const baseRestApiUrl = 'http://overtime.home:8081';

export enum Urls {
	ApiPlatformsBeehiivPostsUrl = `${basePlatformsApiUrl}/api/platforms/v1/beehiiv/posts`,
	ApiPlatformsVivenuEventsUrl = `${basePlatformsApiUrl}/api/platforms/v1/vivenu/events`,
	ApiPlatformsVivenuEventInfo = `${basePlatformsApiUrl}/api/platforms/v1/vivenu/events/info`,
	ApiPlatformsYouTubeVideosUrl = `${basePlatformsApiUrl}/api/platforms/youtube/videos`,
	ApiPlatformsYouTubeChannelsBaseUrl = `${basePlatformsApiUrl}/api/platforms/youtube`,

	ApiRestBannersUrl = `${baseRestApiUrl}/api/banners/v1/public`,
	ApiRestShopifyCollectionsUrl = `${baseRestApiUrl}/api/shopify_collections/v1/public`,

	ApiRestOteEventsUrl = `${baseRestApiUrl}/api/ote_events/v1/web`,
	ApiRestOteGameBaseUrl = `${baseRestApiUrl}/api/ote_games/v1/web`,
	ApiRestOteGamesUrl = `${baseRestApiUrl}/api/ote_games/v1/web`,
	ApiRestOtePlayerGameShotsUrl = `${baseRestApiUrl}/api/ote_game_events/v1/web/game_shots`,
	ApiRestOtePlayerStatsUrl = `${baseRestApiUrl}/api/ote_games_ote_players_ote_teams/v1/web/player_stats`,
	ApiRestOtePlayersUrl = `${baseRestApiUrl}/api/ote_players/v1/web`,
	ApiRestOtePrepVerifyEmail = `${baseRestApiUrl}/api/ote_prep/v1/web/email`,
	ApiRestOteSeasonsUrl = `${baseRestApiUrl}/api/ote_seasons/v1/web`,
	ApiRestOteShopifyDiscountsUrl = `${baseRestApiUrl}/api/ote_shopify_discounts/v1/web`,
	ApiRestOteStatsBaseUrl = `${baseRestApiUrl}/api/ote_stats/v1/web`,
	ApiRestOteTeamsCurrentUrl = `${baseRestApiUrl}/api/ote_teams/v1/web/current`,
	ApiRestOteTeamStandingsBaseUrl = `${baseRestApiUrl}/api/ote_team_standings/v1/web`,
	ApiRestOteTeamStandingsCurrentUrl = `${baseRestApiUrl}/api/ote_team_standings/v1/web/current`,
	ApiRestOteTeamsUrl = `${baseRestApiUrl}/api/ote_teams/v1/web`,
	ApiRestOteThemesUrl = `${baseRestApiUrl}/api/ote_themes/v1/web`,

	AmazonPrimeVideoBaseUrl = 'https://www.amazon.com/gp/video/detail',

	OteLiveAppleStoreUrl = 'https://apps.apple.com/us/app/ote-live/id6738283551',

	BeehiivOteUrl = 'https://ote.beehiiv.com',

	GoogleFormsGroupTickets = 'https://docs.google.com/forms/d/1lceD_8XU0oTbcSTpsg_-XIbpYUYHCU2DyE3auKecaZQ',

	OtePlayerSummaryBaseUrl = 'https://overtimeelite.com/games/player-summary',

	ShopifyCollectionOteUrl = 'https://shop.overtime.tv/collections/ote',

	VivenuTicketsEventBaseUrl = 'https://tickets.itsovertime.com/event',
	VivenuTicketsOtePackagesUrl = 'https://tickets.itsovertime.com/section/packages-3n62',
	VivenuTicketsUrl = 'https://tickets.itsovertime.com',

	YouTubeVideoBaseUrl = `https://www.youtube.com/watch`,
	YouTubeChannelBaseUrl = `https://www.youtube.com`,

	YouTubeOteChannel = `https://www.youtube.com/@OTE`,
	YouTubeOteShorts = `https://www.youtube.com/@OTE/shorts`,
	YouTubeOteGame4Finals = 'https://www.youtube.com/live/WMqaWIlflEs',

	WebToonsOvertimeElite = 'https://www.webtoons.com/en/sports/overtime-elite/list?title_no=6724',
	WebToonsOvertimeEliteEpisode1 = 'https://www.webtoons.com/en/sports/overtime-elite/episode-1/viewer?title_no=6724&episode_no=1',
}

// YouTube Channel IDs that are defined in the platforms service
export enum YouTubeChannelIds {
	// OTE
	Ote = 'ote',
	OteLive = 'ote_live',
	OteShorts = 'ote_shorts',

	// OTE teams
	OteBlueChecks = 'ote_blue_checks',
	OteBlueChecksShorts = 'ote_blue_checks_shorts',
	OteCityReapers = 'ote_city_reapers',
	OteCityReapersShorts = 'ote_city_reapers_shorts',
	OteColdHearts = 'ote_cold_hearts',
	OteColdHeartsShorts = 'ote_cold_hearts_shorts',
	OteDiamondDoves = 'ote_diamond_doves',
	OteDiamondDovesShorts = 'ote_diamond_doves_shorts',
	OteFearOfGodAthletics = 'ote_fear_of_god_athletics',
	OteFearOfGodAthleticsShorts = 'ote_fear_of_god_athletics_shorts',
	OteJellyFam = 'ote_jelly_fam',
	OteJellyFamShorts = 'ote_jelly_fam_shorts',
	OteRwe = 'ote_rwe',
	OteRweShorts = 'ote_rwe_shorts',
	OteYngDreamerz = 'ote_yng_dreamerz',
	OteYngDreamerzShorts = 'ote_yng_dreamerz_shorts',
}
