import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { OteEvent } from '~/src/models/OteEvent';
import { getEstDateTime } from '~/src/overtime-lib/src/www/lib';
import { OvertimeEntityLocation, OvertimeModel } from '.';
import { OteGameOtePlayerOteTeam } from './OteGameOtePlayerOteTeam';
import { OteGameOteTeam } from './OteGameOteTeam';
import { OteGameProfile } from './OteGameProfile';
import { OteSeason } from './OteSeason';
import { OteSeries } from './OteSeries';
import { OteStage, getStageNameAbbreviated } from './OteStage';

export type MatchStatus =
	| 'abandoned'
	| 'bye'
	| 'cancelled'
	| 'confirmed'
	| 'finished'
	| 'if_needed'
	| 'in_progress'
	| 'pending'
	| 'postponed'
	| 'scheduled'
	| null;

export type FixtureType =
	| 'all_star'
	| 'preseason'
	| 'regular'
	| 'final'
	| 'playoff'
	| 'friendly'
	| 'demonstration'
	| null;

export interface OteGame extends OvertimeModel, OvertimeEntityLocation {
	competition_name?: string;
	expected_broadcast?: string;
	fixture_type?: FixtureType;
	game_category?: string;
	game_clock_is_running: boolean;
	game_clock: number;
	is_stats_hidden: boolean;
	location_name?: string;
	match_id: number;
	match_time_zone?: string;
	match_time?: string;
	period_type?: string;
	period?: number;
	primary_color?: number;
	prime_video_id?: string;
	purpose?: string;
	secondary_color?: number;
	series_fixture_number: number;
	shot_clock: number;
	starts_at_timezone: string;
	starts_at_utc_offset: number;
	starts_at: string;
	status?: MatchStatus;
	synergy_id: string;
	theme_name?: string;
	youtube_id?: string;

	ote_event_id?: string;
	ote_game_profile_id?: string;
	ote_season_id?: string;
	ote_series_id?: string;
	ote_stage_id?: string;

	ote_event: OteEvent;
	ote_game_profile: OteGameProfile;
	ote_games_ote_players_ote_teams?: OteGameOtePlayerOteTeam[];
	ote_games_ote_teams: OteGameOteTeam[];
	ote_season?: OteSeason;
	ote_series?: OteSeries;
	ote_stage?: OteStage;
}

const NeededStatuses = ['if_needed'];
const ScheduledStatuses = ['scheduled', 'if_needed', 'pending'];
const LiveStatuses = ['in_progress'];
const CompleteStatuses = ['finished', 'confirmed'];

const isAtLeast15MinutesBefore = (ote_game: OteGame): boolean => {
	return isScheduled(ote_game) && DateTime.now() > DateTime.fromISO(ote_game.starts_at).minus({ minutes: 15 });
};

export const isNeeded = (ote_game: OteGame): boolean => NeededStatuses.includes(ote_game.status);

export const isScheduled = (ote_game: OteGame): boolean => {
	const isScheduledStatus = ScheduledStatuses.includes(ote_game.status);
	const isGameInFuture = DateTime.now().startOf('day') <= DateTime.fromISO(ote_game.starts_at).startOf('day');
	return isScheduledStatus && isGameInFuture;
};
export const isLive = (ote_game: OteGame): boolean => LiveStatuses.includes(ote_game.status);

export const isLiveStreaming = (ote_game: OteGame): boolean =>
	(isLive(ote_game) || isAtLeast15MinutesBefore(ote_game)) && !!(ote_game.prime_video_id || ote_game.youtube_id);

export const isComplete = (ote_game: OteGame): boolean => {
	const isCompletedStatus = CompleteStatuses.includes(ote_game.status);
	const isGameInPast = DateTime.now().startOf('day') > DateTime.fromISO(ote_game.starts_at).startOf('day');
	return isCompletedStatus || isGameInPast;
};

export const isPlayoffs = (ote_game: OteGame): boolean => ote_game.fixture_type === 'playoff';

export const getSeriesGame = (ote_game: OteGame, isAbbreviated: boolean = false): string => {
	const stageName = ote_game.ote_stage?.name;
	/* TODO: Figure out how to get the game number and add to this title */
	const gameNumber = ote_game.series_fixture_number;
	return isAbbreviated
		? `${getStageNameAbbreviated(ote_game.ote_stage)} ${gameNumber ? `G${gameNumber}` : ''}`
		: `${stageName} ${gameNumber ? ` Game ${gameNumber}` : ''}`;
};

export const gameExpectedToBroadcast = (ote_game: OteGame): boolean => {
	return !isNil(ote_game.expected_broadcast);
};

export const gameStartedXHoursAgo = (ote_game: OteGame, hours: number): boolean => {
	const currentTime = getEstDateTime();
	const gameStartTime = getEstDateTime(ote_game.starts_at);
	// Calculate the difference in hours
	const diffInHours = currentTime.diff(gameStartTime, 'hours').hours;
	// Check if the game was completed exactly `hours` ago or earlier
	return diffInHours <= hours;
};
