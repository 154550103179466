import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, ReactNode, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import './LoadingBoundary.scss';
import Spinner from './Spinner';

const LoadingBoundary = ({
	children,
	errorComponent,
	loadingComponent,
}: {
	children: ReactNode;
	errorComponent?: ReactElement;
	loadingComponent?: ReactElement;
}) => (
	<ErrorBoundary
		fallback={
			errorComponent ? (
				errorComponent
			) : (
				<p className="LoadingBoundaryError">
					<FontAwesomeIcon icon={faTriangleExclamation} /> Unknown error.
				</p>
			)
		}
	>
		<Suspense
			fallback={
				loadingComponent ? (
					loadingComponent
				) : (
					<div className="LoadingBoundarySuspense">
						<Spinner />
					</div>
				)
			}
		>
			{children}
		</Suspense>
	</ErrorBoundary>
);

export default LoadingBoundary;
