import React, { ReactEventHandler, ReactNode } from 'react';
import './Button.scss';

const Button = ({
	children,
	href,
	inverted = false,
	...rest
}: {
	children: ReactNode;
	href?: string;
	inverted?: boolean;
	onClick?: ReactEventHandler;
	className?: string;
	style?: object;
}) =>
	href ? (
		<a className={`Button${inverted ? ' Inverted' : ''}`} href={href} {...rest}>
			<div>{children}</div>
		</a>
	) : (
		<button className={`Button${inverted ? ' Inverted' : ''}`} {...rest}>
			<div>{children}</div>
		</button>
	);

export default Button;
