import { faInstagram, faTiktok, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import React from 'react';
import { imgAppleStoreWhiteSvg, imgOteLogoFooterSvg } from '~/src/images';
import { Urls } from '~/src/lib/enums/urls';
import { OteTeam } from '~/src/models/OteTeam';
import useFetch from '~/src/overtime-lib/src/www/hooks/useFetch';
import ContentContainer from './ContentContainer';
import './Footer.scss';
import LoadingBoundary from './LoadingBoundary';

const _Teams = () => {
	const fetchResult = useFetch<OteTeam[]>({
		url: `${Urls.ApiRestOteTeamsCurrentUrl}`,
		key: 'ote_teams',
	});
	const teams = fetchResult?.result.read();
	return (
		<div className="Teams">
			{sortBy(teams, 'name').map((t) => (
				<a className="Logo" href={`/teams/${t.slug}`} key={t.id}>
					<img src={`https://images.overtime.tv/${t.logo_path}`} alt={t.name} />
				</a>
			))}
		</div>
	);
};

const _LegalLinks = () => {
	const links = [
		<a href="/ticket_terms">Ticket Terms</a>,
		<a href="/covid">COVID-19</a>,
		<a href="https://overtime.tv/terms">Terms</a>,
		<a href="https://overtime.tv/privacy">Privacy</a>,
		<a href="https://overtime.tv/copyright">DMCA</a>,
	];
	return (
		<section className="Links">
			{links.map((link, i) => (
				<React.Fragment key={`link-${i}`}>
					<>{link}</>
					<>{i < links.length - 1 && <span className="Separator">|</span>}</>
				</React.Fragment>
			))}
		</section>
	);
};

const Footer = () => {
	return (
		<footer className="Footer">
			<ContentContainer>
				<nav>
					<div className="LogoContainer">
						<div className="Logo">
							<a href="/">
								<img alt="Home" width={76} height={92} src={imgOteLogoFooterSvg} />
							</a>
						</div>
					</div>

					<div className="LinkContainer">
						<section className="Column">
							<div className="Heading">League</div>
							<div className="Links">
								<a href="/teams">Teams</a>
								{/* <a  href="/standings">
									Standings
								</a> */}
								<a href="/schedule">Schedule</a>
								<a href="/scores">Scores</a>
								<a href="/players">Players</a>
								<a href="/statistics">Stats</a>
								<a href={Urls.BeehiivOteUrl}>News</a>
								<a href="/tickets">Ticket Info</a>
							</div>
						</section>

						<section className="Column">
							<div className="Heading">About</div>
							<div className="Links">
								<a href="/about/story">Our Story</a>
								<a href="/about/leadership">Leadership</a>
								<a href="/about/academy">Academy</a>
								<a href="/about/hpd">HPD</a>
								<a href="/about/arena">Arena</a>
								<a href="/about/colleges">Recruiters</a>
								{/* <a  href="/about/staff">
									Staff List
								</a> */}
								<a href={Urls.GoogleFormsGroupTickets}>Group Tickets</a>
							</div>
						</section>
						<section className="Column Follow">
							<div className="Heading">Follow</div>
							<div className="Links">
								<a href={Urls.OteLiveAppleStoreUrl} title="Apple Store">
									<img alt="Apple Store" src={imgAppleStoreWhiteSvg} />
								</a>
								<div className="Social">
									<a href="https://www.instagram.com/ote/" title="Instagram">
										<FontAwesomeIcon icon={faInstagram} size="2x" />
									</a>
									<a href="https://www.tiktok.com/@ote?" title="TikTok">
										<FontAwesomeIcon icon={faTiktok} size="2x" />
									</a>
									<a href="https://twitter.com/OvertimeElite" title="X">
										<FontAwesomeIcon icon={faXTwitter} size="2x" />
									</a>
									<a href="https://www.youtube.com/channel/UCqSAy-3f7uOKTGJzw8RP-JQ" title="YouTube">
										<FontAwesomeIcon icon={faYoutube} size="2x" />
									</a>
								</div>
							</div>
						</section>
					</div>
				</nav>

				<LoadingBoundary errorComponent={<></>}>
					<_Teams />
				</LoadingBoundary>
			</ContentContainer>

			<div className="LegalFooter">
				<ContentContainer>
					<div className="LegalContainer">
						<section className="Copyright">
							<div>&copy; {new Date().getFullYear()} Overtime Sports, Inc. All Rights Reserved.</div>
						</section>

						<_LegalLinks />
					</div>
					<div className="LegalContainer">
						<section className="Copyright Pokemon">
							&copy; {new Date().getFullYear()} Pokémon. &copy; 1995-{new Date().getFullYear()} Nintendo / Creatures
							Inc. / GAME FREAK inc. TM, &reg;, and character names are trademarks of Nintendo.
						</section>
					</div>
				</ContentContainer>
			</div>
		</footer>
	);
};
export default Footer;
