import _ from 'lodash';
import { OvertimeEntityLocation, OvertimeModel } from '.';
import { Urls } from '../lib/enums/urls';
import useFetch from '../overtime-lib/src/www/hooks/useFetch';
import { OteTeam } from './OteTeam';

export enum OteThemeName {
	Pokemon = 'pokemon',
}

export interface OteTheme extends OvertimeModel, OvertimeEntityLocation {
	theme_name?: OteThemeName;
	ote_team_id?: string;
	logo_path?: string;
	primary_color?: number;
	secondary_color?: number;
	foreground_color?: number;
	secondary_foreground_color?: number;

	// Relationships
	ote_team?: OteTeam;
}

export const getThemeTeam = (ote_team_id: string, theme_name: string) => {
	if (_.isNil(ote_team_id) || _.isNil(theme_name)) {
		return null;
	}

	const ote_theme = useFetch<OteTheme>({
		url: `${Urls.ApiRestOteThemesUrl}/${ote_team_id}/${theme_name}`,
		key: 'ote_theme',
	})?.result.read();

	return ote_theme;
};
