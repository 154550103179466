import { useEffect } from 'react';

const ExternalRedirect = ({ to }) => {
	useEffect(() => {
		window.location.href = to;
	}, [to]);

	return null; // No UI rendered, just performs the redirect
};

export default ExternalRedirect;
